<template>
    <div class="outSide">
        <van-nav-bar title="收房申请" fixed @click-left="leftReturn" left-arrow>
        </van-nav-bar>
        <div class="content">
            <div class="part">
                <div class="part-inputpart">
                    <span class="part-subtitle">地址信息</span>
                    <div  class="part-inputpart-row" @click="searchAddress()">
                        <span :class=" 0==houseInfo.area.toString().trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">所在小区</span>
                        <span class="content-divide">|</span>
                        <!-- <span class="inputSelect">{{ roomHouseListNameValue }}</span> -->
                        <input class="inputSelect" type="text" v-model="roomHouseListNameValue" placeholder="请选择" readonly></input>
                        <img class="part-inputpart-row-right part-inputpart-row-right-upArrow" src="../../../assets/images/youjiantou.png">
                    </div>

                    <div class="part-inputpart-row">
                        <span :class=" 0==houseInfo.area.toString().trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">栋座</span>
                        <span class="content-divide">|</span>
<!--                        <select class="selectDownMenu" v-model="houseSeatNameValue" @change="onConfirmSeat($event)">-->
<!--                            <option :value="houseSeatNameValue" v-for="(item , i) in houseSeatName" :key="i" >{{item}}-->
<!--                            </option>-->
<!--                        </select>-->
                        <input class="inputSelect" type="tel" v-model=" houseSeatNameValue " placeholder="请选择" @click="showhouseSeat = true" readonly>
                        <div class="arrows" @click="showhouseSeat = true"></div>
                        <van-popup round position="bottom" v-model="showhouseSeat">
                            <van-picker show-toolbar :columns="houseSeatName"  @cancel="showhouseSeat = false" @confirm="onConfirmSeat"/>
                        </van-popup>
                    </div>
                    <div class="part-inputpart-row">
                        <span :class=" 0==houseInfo.area.toString().trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">单元</span>
                        <span class="content-divide">|</span>
                        <input class="inputSelect" type="tel" v-model="houseUnitNameValue" placeholder="请选择" @click="showhouseUnit = true" readonly>
                        <div class="arrows" @click="showhouseUnit = true"></div>
                        <van-popup round position="bottom" v-model="showhouseUnit">
                            <van-picker show-toolbar :columns="houseUnitName"  @cancel="showhouseUnit = false" @confirm="onConfirmUnit"/>
                        </van-popup>
                    </div>
                    <div class="part-inputpart-row">
                        <span :class=" 0==houseInfo.area.toString().trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">门牌号</span>
                        <span class="content-divide">|</span>
                        <input class="inputSelect" type="tel" v-model="houseDoorNumNameValue" placeholder="请选择" @click="showhouseNum = true" readonly>
                        <div class="arrows" @click="showhouseNum = true"></div>
                        <van-popup round position="bottom" v-model="showhouseNum">
                            <van-picker show-toolbar :columns="houseDoorNumName"  @cancel="showhouseNum = false" @confirm="onConfirmNum"/>
                        </van-popup>
                    </div>
                </div>
            </div>
            <div class="part">
                <span class="part-subtitle">房屋信息</span>
                <div class="part-inputpart">
                    <div class="part-inputpart-row">
                        <div :class=" 0!=houseInfo.householdTypeTransformation.firstNum.toString().trim().length && 0!=houseInfo.householdTypeTransformation.secondNum.toString().trim().length ? 'content-have' : 'content-none' "></div>
                        <span class="part-inputpart-row-header">改造户型</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext"></span>
                        <div class="part-inputpart-row-right part-inputpart-row-housetype">
                            <input type="tel" style="width: 1rem;text-align: center" oninput="if(value.length>1)value=value.slice(0,1)" v-model="houseInfo.householdTypeTransformation.firstNum" placeholder="0" disabled/>
                            <span>+</span>
                            <input type="tel" style="width: 1rem;text-align: center" oninput="if(value.length>1)value=value.slice(0,1)" v-model="houseInfo.householdTypeTransformation.secondNum" placeholder="0"/>


                        </div>
                    </div>
                    <div class="part-inputpart-row">
                        <span :class=" 0!==houseInfo.rent.toString().trim().length ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">租金</span>
                        <span class="content-divide">|</span>
                        <input type="tel" v-model="houseInfo.rent" placeholder="输入金额">
                        <span class="part-inputpart-row-right">元/月</span>
                    </div>
                    <div id="orientationPanel" class="part-inputpart-row" @click="isOrientationShow = !isOrientationShow">
                        <span :class=" 0 != Object.keys(houseInfo.orientation).toString().length ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">朝向</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext" :class="{'part-inputpart-row-normaltext':0 != Object.keys(houseInfo.orientation).toString().length}">{{houseInfo.orientation.dictionaryTitle}}</span>
                        <img class="part-inputpart-row-right" :class=" isOrientationShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
<!--                    <div>-->
<!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isOrientationShow">-->
<!--                            <div class="part-inputpart-dropDownList-option" :class="item==houseInfo.orientation ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in orientationArr" @click="selectOrientationOption(item)">{{item.dictionaryTitle}}</div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="part-inputpart-row">
                        <span :class=" 0==houseInfo.area.toString().trim().length ? 'content-none' : 'content-have' "></span>
                        <span class="part-inputpart-row-header">面积</span>
                        <span class="content-divide">|</span>
                        <input type="tel" v-model="houseInfo.area" placeholder="输入面积">
                        <span class="part-inputpart-row-right">m²</span>
                    </div>
                    <div id="decorationStylePanel" class="part-inputpart-row" @click="isDecorationStyleShow = !isDecorationStyleShow">
                        <span :class=" houseInfo.decorationStyle.dictionaryTitle? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">装修风格</span>
                        <span class="content-divide">|</span>
                        <span class="part-inputpart-row-graytext" :class="houseInfo.decorationStyle.dictionaryTitle ? 'part-inputpart-row-normaltext':'decorationStyleDefaul' ">{{houseInfo.decorationStyle.dictionaryTitle ?houseInfo.decorationStyle.dictionaryTitle : '请选择' }}</span>
                        <img class="part-inputpart-row-right" :class=" isDecorationStyleShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                    </div>
<!--                    <div>-->
<!--                        <div class="part-inputpart part-inputpart-dropDownList" v-if="isDecorationStyleShow">-->
<!--                            <div class="part-inputpart-dropDownList-option" :class="item==houseInfo.decorationStyle ? 'part-inputpart-dropDownList-option-selected' : '' "-->
<!--                                 v-for="item in decorationStyleArr" @click="selectDecorationStyleOption(item)" :key="item.id">-->
<!--                                {{item.dictionaryTitle}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="part-inputpart-row">
                        <span :class=" 0!=houseInfo.decorationBudget.toString().trim().length ? 'content-have' : 'content-none' "></span>
                        <span class="part-inputpart-row-header">装修预算</span>
                        <span class="content-divide">|</span>
                        <input type="tel" v-model="houseInfo.decorationBudget" placeholder="输入预算金额">
                        <span class="part-inputpart-row-right">元</span>
                    </div>
                    <div class="part-inputpart-row">
<!--                        <span :class=" 0!=houseInfo.renovationTime.toString().trim().length ? 'content-have' : 'content-none' "></span>-->
                        <span class="part-inputpart-row-header dev">装修期</span>
                        <span class="content-divide">|</span>
                        <input type="tel" v-model="houseInfo.renovationTime" placeholder="输入装修天数">
                        <span class="part-inputpart-row-right">天</span>
                    </div>
                    <div class="part-inputpart-row">
<!--                        <span :class=" 0!=houseInfo.allFreeLeaseterm.toString().trim().length ? 'content-have' : 'content-none' "></span>-->
                        <span class="part-inputpart-row-header dev">总免租期</span>
                        <span class="content-divide">|</span>
                        <input type="tel" v-model="houseInfo.allFreeLeaseterm" placeholder="输入总免租期">
                        <span class="part-inputpart-row-right">天</span>
                    </div>
                    <div class="part-inputpart-textarea">
                        <span class="part-inputpart-row-enablenon part-inputpart-row-header">申请描述</span>
                        <textarea v-model="houseInfo.describe" placeholder="请描述一下你的房子"></textarea>
                    </div>
                </div>
            </div>
            <div class="backgroundBtn">
            <div @click="saveData" class="part part-button" :class="0==houseInfo.householdTypeTransformation.firstNum.toString().trim().length ||
                                                  0==houseInfo.householdTypeTransformation.secondNum.toString().trim().length ||
                                                  0==houseInfo.rent.toString().trim().length || 0 == Object.keys(houseInfo.orientation).toString().length ||
                                                  0==houseInfo.area.toString().trim().length || 0==Object.keys(houseInfo.decorationStyle).toString().length ||
                                                  0==houseInfo.decorationBudget.toString().trim().length
                                                  ? '' : 'part-button-enabled' ">保存</div>
            </div>
        </div>
      <van-popup v-model="isOrientationShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="orientationArr"
            @cancel="isOrientationShow = false"
            :default-index="orientationIndex"
            value-key="dictionaryTitle"
            @confirm="selectOrientationOption"
        />
      </van-popup>
      <van-popup v-model="isDecorationStyleShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="decorationStyleArr"
            @cancel="isDecorationStyleShow = false"
            :default-index="decorationStyleIndex"
            value-key="dictionaryTitle"
            @confirm="selectDecorationStyleOption"
        />
      </van-popup>
    </div>
</template>

<script>

    import {
        NavBar,Picker,field,popup,Toast, Search
    } from 'vant'
    import {
        addcolllectRoom,
        queryBaseData,
        queryEstateList,
        queryEstatePedestalList, queryEstateRoomDetailList,
        queryEstateUnitList
    } from "../../../getData/getData";
    import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import houseDetails from "../../houseResources/houseDetails/houseDetails";
    import eventBus  from "../../../components/rongxun/rx-navBar/envbus.js";


    export default {

        name : 'ApplicationForRehousing',

        components: {
            [NavBar.name]: NavBar,
            [Picker.name]: Picker,
            [field.name]: field,
            [popup.name]: popup,
            [Toast.name]: Toast
        },

        data() {
            return {
              orientationIndex: '',
              decorationStyleIndex: '',
                houseRoomId : '',
                //门牌号
                houseDoorNum : [],
                houseDoorNumName : [],
                houseDoorNumNameValue : '',
                showhouseNum : false,
                //单元
                houseUnit : [],
                houseUnitName : [],
                houseUnitNameValue : '',
                showhouseUnit : false ,
                //栋座数组
                houseSeat : [],
                houseSeatName : [],
                houseSeatNameValue : '',
                showhouseSeat : false ,
                //楼盘数组
                roomHouseListId : '',
                roomHouseList : [],
                roomHouseListName : [],
                roomHouseListNameValue: '',
                showPicker: false,
                houseDetail:[],
                // 朝向
                isOrientationShow: false,
                isOrientationSelected: false,
                // 装修风格
                isDecorationStyleShow: false,
                isDecorationStyleSelected: false,
                housingSourceAddress: '',
                houseInfo: {
                    householdTypeTransformation: {
                        firstNum: '',
                        secondNum: ''
                    },
                    id:'',
                    estateRoom_id:'',
                    rent: '',
                    renovationTime : '',
                    orientation: {
                    },
                    area: '',
                    decorationStyle: {
                    },
                    //decorationPeriod: '',
                    decorationBudget: '',
                    allFreeLeaseterm: '',
                    describe: ''
                },
                orientationArr: [],
                decorationStyleArr: [],
            }
        },

        //监听数值变化
        watch: {
            //(/\D/g,'')
            'houseInfo.householdTypeTransformation.firstNum': function (val) {
                this.houseInfo.householdTypeTransformation.firstNum= val
            },
            'houseInfo.householdTypeTransformation.secondNum': function (val) {
                this.houseInfo.householdTypeTransformation.secondNum = val
            },
            'houseInfo.area': function (newVal,oldVal) {
                var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g;
                if(!reg.test(newVal)){
                    if(newVal == ''){
                        this.houseInfo.area = '';
                        return;
                    }
                    this.houseInfo.area = oldVal
                }else{
                    this.houseInfo.area = newVal;
                }
            },
            'houseInfo.rent': function (newVal,oldVal) {
                var reg = /^(\d{0,8})(\.(\d{0,2}))?$/g;
                if(!reg.test(newVal)){
                    if(newVal == ''){
                        this.houseInfo.rent = '';
                        return;
                    }
                    this.houseInfo.rent = oldVal
                }else{
                    this.houseInfo.rent = newVal;
                }
            },
            'houseInfo.decorationBudget': function (val) {
                this.houseInfo.decorationBudget = val.toString().replace(/\D/g,'')
            }
        },

        mounted() {
            //初始化字典数据
            this.initDicData()
            //初始化数据赋值
            this.initData();
            // if(this.$route.query.estate_id==undefined){
            //      this.initQueryEstateList();
            // }
        },

        methods: {
            //查找小区地址
            searchAddress(){
                this.$router.push({
                    name:'chooseCommunity',
                    query:{
                            
                    }
                })
            },
            // //楼盘下拉
            // onConfirm(value,values) {
            //     this.roomHouseListNameValue = value;
            //     this.showPicker = false;
            //     this.roomHouseListId=values
            //     this.houseSeat =[]
            //     this.houseSeatNameValue = ''
            //      this.houseUnit =[]
            //     this.houseUnitNameValue = ''
            //     this.houseDoorNum = []
            //     this.houseDoorNumNameValue = ''
            //     this.initQueryEstatePedestalList(values+1)
            // },
            //栋座下拉
            onConfirmSeat(value){
                this.houseSeatNameValue = value;
                this.showhouseSeat = false;
                let  houseSeatId = '';
                for(let i =0 ; i<this.houseSeat.length;i++){
                    if(this.houseSeat[i].estatePedestalName==value){
                        houseSeatId=this.houseSeat[i].epId
                    }
                }
                this.houseUnit =[]
                this.houseUnitNameValue = ''
                this.houseDoorNum = []
                this.houseDoorNumNameValue = ''
               this.initQueryEstateUnitList(houseSeatId)
            }  ,
            //单元下拉
            onConfirmUnit( value){
                this.houseUnitNameValue = value;
                this.showhouseUnit = false;
                let  houseUnitId = '';
                for(let i =0 ; i<this.houseUnit.length;i++){
                    if(this.houseUnit[i].estateUnitName==value){
                        houseUnitId=this.houseUnit[i].euId
                    }
                }
                this.houseDoorNum = []
                this.houseDoorNumNameValue = ''
                this.initQueryEstateRoomDetailList(houseUnitId);
            },
            //门牌号下拉
            onConfirmNum( value){
                this.houseDoorNumNameValue = value;
                this.showhouseNum = false;
                let  houseRoomIdNum = '';
                for(let i =0 ; i<this.houseDoorNum.length;i++){
                    if(this.houseDoorNum[i].title==value){
                        houseRoomIdNum=this.houseDoorNum[i].erId
                    }
                }
                this.houseRoomId = houseRoomIdNum
                // console.log(this.houseRoomId+'单元下拉')
            },
        //  初始化字典数据
            initDicData:function(){
                const that = this
                let initData = {}
                initData.dbName = ['decorationStyle','houseOrientation']
                initData.fdName = []
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.decorationStyleArr = response.data.data.decorationStyle
                        that.orientationArr = response.data.data.houseOrientation
                    })
                })
            },
            //初始化小区楼盘
            initQueryEstateList : function(values){
                let that = this
                let initData = {}
                queryEstateList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response, () =>{
                        that.roomHouseList=response.data.data.roomHouseList
                        for(let i = 0 ; i<that.roomHouseList.length;i++){
                            that.roomHouseListName[i]=that.roomHouseList[i].estateName
                            if(values!=undefined&&that.roomHouseList[i].id==values){
                                that.roomHouseListNameValue=that.roomHouseList[i].estateName
                            }
                        }
                        // console.log(that.roomHouseList)
                    })
                })
            },
            //初始化楼盘栋座
            initQueryEstatePedestalList : function(values1,values2){
                let that = this
                let initData = {}
                initData.estate_id=values1
                that.idHouse=values1
                queryEstatePedestalList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response, () =>{
                        that.houseSeatName = []
                        that.houseSeat=response.data.data.roomHouseList
                        for(let i = 0 ; i<that.houseSeat.length;i++){
                            that.houseSeatName[i]=that.houseSeat[i].estatePedestalName
                            if(values2!=undefined&&that.houseSeat[i].epId==values2){
                                that.houseSeatNameValue=that.houseSeat[i].estatePedestalName
                            }
                        }
                        // console.log(that.houseSeat)
                    })
                })
            },
            //初始化单元
            initQueryEstateUnitList : function(values1,values2){
                let that = this
                let initData = {}
                initData.estatePedestal_id=values1
                that.idHouse=values1
                queryEstateUnitList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response, () =>{
                        that.houseUnitName = []
                        that.houseUnit=response.data.data.roomHouseList
                        for(let i = 0 ; i<that.houseUnit.length;i++){
                            that.houseUnitName[i]=that.houseUnit[i].estateUnitName
                            if(values2!=undefined&&that.houseUnit[i].euId==values2){
                                that.houseUnitNameValue=that.houseUnit[i].estateUnitName
                            }
                        }
                        // console.log(that.houseUnit)
                    })
                })
            },
            //初始化门牌号erId
            initQueryEstateRoomDetailList : function(values1,values2){
                let that = this
                let initData = {}
                initData.estateUnit_id=values1
                queryEstateRoomDetailList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response, () =>{
                        that.houseDoorNumName = []
                        that.houseDoorNum=response.data.data.roomHouseList
                        for(let i = 0 ; i<that.houseDoorNum.length;i++){
                            that.houseDoorNumName[i]=that.houseDoorNum[i].title
                            if(values2!=undefined&&that.houseDoorNum[i].erId==values2){
                                that.houseDoorNumNameValue=that.houseDoorNum[i].title
                                that.houseRoomId=that.houseDoorNum[i].erId
                                // console.log(that.houseRoomId+'初始化')
                            }
                        }
                    //    console.log(that.houseDoorNum)
                    })
                })
            },

            initData(){
                let that = this;
                //房间初始数量
                if(that.$route.query.bedroomCount){
                    that.houseInfo.householdTypeTransformation.firstNum = that.$route.query.bedroomCount;
                }
                //改造数量
                if(that.$route.query.changHouseNum){
                    that.houseInfo.householdTypeTransformation.secondNum = that.$route.query.changHouseNum;
                }
                //租金
                that.houseInfo.rent = that.$route.query.rentMoney;
                //朝向
                // console.log(that.$route.query.orientationName)
                if(that.$route.query.orientationName)
                        that.houseInfo.orientation.dictionaryTitle = that.$route.query.orientationName;
                // console.log(that.$route.query.orientationName+'朝向')
                //朝向ID
                if(that.$route.query.orientation_id)
                        that.houseInfo.orientation.orientation_id = that.$route.query.orientation_id;
                //面积
                that.houseInfo.area = that.$route.query.builtUpArea;
                //装修风格
                // that.houseInfo.decorationStyle.dictionaryTitle=that.$route.query.decorationStyle;
                // //收房描述
                // that.houseInfo.describe = that.$route.query.houseRemarks;
                //潜房ID
                that.houseInfo.id = that.$route.query.id;
                // //装修预算
                // if(that.$route.query.decorationMoney)
                //         that.houseInfo.decorationBudget = that.$route.query.decorationMoney
                // 房源地址
                if(that.$route.query.estate_id!=undefined){
                    console.log(that.$route.query)
                    that.initQueryEstateList(that.$route.query.estate_id)
                }
                //上一页传过来的小区id存在  
                console.log('initData')
               if(that.$route.query.estate_id!=undefined){
                   that.initQueryEstatePedestalList(that.$route.query.estate_id,that.$route.query.estatePedestal_id)
               }
               if(that.$route.query.estatePedestal_id!=undefined){
                   that.initQueryEstateUnitList(that.$route.query.estatePedestal_id,that.$route.query.estateUnit_id)
               }
                if(that.$route.query.estateUnit_id!=undefined){
                    that.initQueryEstateRoomDetailList(that.$route.query.estateUnit_id,that.$route.query.estateRoom_id)
                }
               //  that.initQueryEstateList(that.$route.query.estate_id)
               //  that.initQueryEstatePedestalList(that.$route.query.estate_id,that.$route.query.estatePedestal_id)
               //  that.initQueryEstateUnitList(that.$route.query.estatePedestal_id,that.$route.query.estateUnit_id)
               //  that.initQueryEstateRoomDetailList(that.$route.query.estateUnit_id,that.$route.query.estateRoom_id)
            },

            // 保存数据
            saveData: function () {
                var that = this
                let initData = {}
                // if(0==that.houseInfo.householdTypeTransformation.firstNum.toString().trim().length ||
                //     // 0==that.houseInfo.householdTypeTransformation.secondNum.trim().length ||
                //     0==that.houseInfo.rent.toString().trim().length || !that.isOrientationSelected ||
                //     0==that.houseInfo.area.toString().trim().length || !that.isDecorationStyleSelected ||
                //     0==that.houseInfo.decorationBudget.toString().trim().length ) return
                //别处获取的
                //潜在资源id
                initData.id = that.houseInfo.id
                //用户id
                initData.user_id = globaluserId()
                //员工id
                initData.staff_id = getStaffId()
                //楼盘字典房间id
                initData.estateRoom_id = that.houseRoomId
                //本表单
                //改造间数
                initData.changHouseNum = that.houseInfo.householdTypeTransformation.secondNum
                //朝向id
                if(that.houseInfo.orientation.id==undefined||that.houseInfo.orientation.id==''){
                    for(let i=0; i<that.orientationArr.length;i++){
                        if(that.orientationArr[i].dictionaryTitle==that.houseInfo.orientation.dictionaryTitle){
                            that.houseInfo.orientation.id = that.orientationArr[i].id
                        }
                    }
                }
                initData.orientation_id = that.houseInfo.orientation.id
                //建筑面积
                initData.builtUpArea = that.houseInfo.area
                //装修方式id
                if(that.houseInfo.decorationStyle.id==undefined||that.houseInfo.decorationStyle.id==''){
                    for(let i=0; i<that.decorationStyleArr.length;i++){
                        if(that.decorationStyleArr[i].dictionaryTitle==that.houseInfo.decorationStyle.dictionaryTitle){
                            that.houseInfo.decorationStyle.id = that.decorationStyleArr[i].id
                        }
                    }
                }
                initData.decorationStyle_id = that.houseInfo.decorationStyle.id
                //装修金额
                initData.decorationMoney = that.houseInfo.decorationBudget
                //描述
                initData.remarks = that.houseInfo.describe
                //租金
                initData.rentMoney = that.houseInfo.rent
                //免租期
                initData.noFeeTime = that.houseInfo.allFreeLeaseterm
                //装修期
                initData.renovationTime = that.houseInfo.renovationTime
                //decorationMoney装修
                // console.log(initData)
                addcolllectRoom(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that,'保存成功')
                    that.routerFun(that.houseInfo.id)
                    })
                })
            },
            //保存成功跳转
            routerFun(id){
                this.$router.push({
                    name : 'PotentialHouseList',
                    query : {
                        id : id
                    }
                })
            }  ,
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            clickOrientationShow() {
                this.isOrientationShow = !this.isOrientationShow;
                var panel = document.getElementById('orientationPanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isOrientationShow = false
                        }
                    })
                }
            },
            selectOrientationOption(item,index){
                this.houseInfo.orientation = item
              this.orientationIndex = index
                this.isOrientationShow = false
                this.isOrientationSelected = true
            },
            clickDecorationStyleShow() {
                this.isDecorationStyleShow = !this.isDecorationStyleShow;
                var panel = document.getElementById('decorationStylePanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isDecorationStyleShow = false
                        }
                    })
                }
            },
            selectDecorationStyleOption(item,index){
                // console.log(item)
                this.houseInfo.decorationStyle = item
              this.decorationStyleIndex = index
                this.isDecorationStyleShow = false
                this.isDecorationStyleSelected = true
            }

        },


        activated(){
            eventBus.$on('chooseCommunity', function(data){
                    //赋值
                    if(data.searchInfo!=this.roomHouseListNameValue && data.searchInfo!=''){
                        this.roomHouseListNameValue = data.searchInfo
                        this.roomHouseListId = data.searchId
                        this.initQueryEstatePedestalList(data.searchId)
                        this.houseSeatNameValue=''  //楼栋
                        this.houseUnitNameValue = '' //单元
                        this.houseDoorNumNameValue=''  //门牌号
                    }
            }.bind(this));
        },
        beforeDestroy() {
          eventBus.$off('ApplicationForRehousing')
        }  

    }
</script>

<style scoped>
    .outSide{
        width: 100%;
        overflow: hidden;
    }
    .selectDownMenu{
        width: 210px;
        margin-left: 15px;
        border: none;
        height: 40px;
        line-height: 40px;
    }

/*剪头*/
    .arrows{
        /*margin-left: 50px;*/
        margin-right: 15px;
        height: 0;
        width: 0;
        border: 4px solid ;
        border-color: black transparent transparent transparent;
    }
    /*input框*/
  .part-inputpart-row .inputSelect{
        width: 100%;
      /*border: 0;*/
      /*border: none;*/
    }
    .content{
        margin-top: 52px;
        margin-bottom: 80px;
    }

    .part {
        margin: 15px;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
    }
    .backgroundBtn{
        position: fixed;
        width: 100%;
        height: 70px;
        background-color: #ffffff;
        bottom: 0;
    }
    .part-button{
        width: 92%;
        bottom: 0px;
        height: 40px;
        border-radius: 8px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        font-size: 15px;
        font-weight: 900;
        margin:  15px;
    }

    .part-button-enabled{
        background-image:linear-gradient(to right,#ffc274, #ff5d3b);
    }

    /*.part-inputpart div:last-child{*/
    /*    border: none;*/
    /*}*/

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        background-color: #ffffff;
        margin-top: 2px;
    }
    .part-inputpart-row:first-of-type{
        border-radius: 8px 8px 0 0 ;
    }

    .part-inputpart-row-header{
        font-weight: 900;
    }
    .dev{
        margin-left: 27px;
    }
    .part-inputpart-row-address{
        padding: 12px 10px;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-address span:first-child{
        margin-right: 8px;
    }

    .part-inputpart-row-address span:last-child{
        white-space: pre-wrap;
        line-height: 25px;
        color: #999999;
        font-size: 14px;
    }

    .part-inputpart-textarea{
        display: flex;
        flex-direction: column;
        margin-top: 2px;
        background-color: white;
        padding-top: 10px;
    }

    .part-inputpart-textarea textarea{
        height: 60px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 10px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder{
        color: #d8d8d8;
    }

    .part-inputpart-row input {
        border: 0;
        width: 100%;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-inputpart-dropDownList{
        height: 23%;
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-inputpart-dropDownList-option{
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-inputpart-dropDownList-option-selected{
        color: #ff5d3b;
    }

    .part-inputpart-row-enablenon {
        margin-left: 26px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
    }

    .part-inputpart-row-normaltext{
        color: black;
    }

    .part-inputpart-row-redtext{
        width: 100%;
        color: #ff5d3b;
    }

    .part-inputpart-row-housetype input{
        width: 8px;
    }

    .part-inputpart-row-housetype span{
        margin: 0 8px;
    }

    .part-inputpart-row-right{
        float: right;
        margin-right: 10px;
    }

    .part-inputpart-row-right-downArrow{
        width: 9px;
    }

    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }
    .decorationStyleDefaul{
        color: #d5d5d5;
    }
    input:disabled{
        height: 35px;
    }


</style>
